<template>
  <v-row class="customer-detail custom-border-top">
    <v-col md="12" sm="12">
      <v-skeleton-loader
        class="custom-skeleton"
        v-if="pageLoading"
        type="table-tbody"
      >
      </v-skeleton-loader>
      <v-simple-table v-else class="simple-table">
        <template v-slot:default>
          <thead>
            <tr>
              <td>
                <v-checkbox
                  v-model="selectedAll"
                  class="hide-margin"
                  color="cyan"
                  dense
                  hide-details
                ></v-checkbox>
              </td>
              <td class="font-size-15 font-weight-600">Service Address #</td>
              <td class="font-size-15 font-weight-600">Address</td>
              <td class="font-size-15 font-weight-600">Status</td>
            </tr>
          </thead>
          <tbody>
            <template v-if="rowData.length > 0">
              <tr v-for="(address, index) in rowData" :key="index">
                <td>
                  <v-checkbox
                    v-model="selectedRows"
                    class="hide-margin"
                    color="cyan"
                    dense
                    hide-details
                    v-bind:value="address.id"
                  ></v-checkbox>
                </td>
                <td
                  link
                  v-on:click="selectProperty(address.id)"
                  class="font-size-14 font-weight-500 text-uppercase"
                >
                  <Barcode :barcode="address.barcode"></Barcode>
                </td>
                <td
                  link
                  v-on:click="selectProperty(address.id)"
                  class="font-size-14 font-weight-500"
                >
                  {{ address.property_address }}
                </td>
                <td class="font-size-14 font-weight-500">
                  <Status :status="address.activated"></Status>
                </td>
              </tr>
            </template>
            <tr v-else-if="!pageLoading">
              <td colspan="4">
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                >
                  <img
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no property at the moment.
                </p>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import Status from "@/view/pages/partials/Status.vue";
import Barcode from "@/view/pages/partials/Barcode.vue";

export default {
  name: "property-address",
  title: "Customer",
  mixins: [CommonMixin],
  data() {
    return {
      rowData: [],
      selectedRows: [],
    };
  },
  props: {
    customer: {
      type: Object,
      required: true,
      default: function () {
        return {};
      },
    },
    pageLoading: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Status,
    Barcode,
  },
  watch: {
    selectedRows(param) {
      this.$emit("selectedRows", param);
    },
    customer() {
      this.assignData();
    },
  },
  methods: {
    selectProperty(param) {
      this.$emit("selectProperty", param);
    },
    getAddress(address) {
      let addressArr = [];
      if (address.unit) {
        addressArr.push(address.unit);
      }
      if (address.street_1) {
        addressArr.push(address.street_1);
      }
      if (address.street_2) {
        addressArr.push(address.street_2);
      }
      if (address.zip_code) {
        addressArr.push(address.zip_code);
      }
      if (this.lodash.isEmpty(address.city) === false) {
        addressArr.push(address.city.name);
      }
      if (this.lodash.isEmpty(address.state) === false) {
        addressArr.push(address.state.name);
      }
      if (this.lodash.isEmpty(address.country) === false) {
        addressArr.push(address.country.name);
      }
      return addressArr.join(", ");
    },
    assignData() {
      const _this = this;
      if (_this.lodash.isEmpty(_this.customer.address) === false) {
        let customerAddress = _this.lodash.map(
          _this.customer.address,
          function (data) {
            if (data.type === 1) return data;
          }
        );
        _this.rowData = _this.lodash.without(customerAddress, undefined);
      }
    },
  },
  mounted() {
    const _this = this;
    _this.assignData();
    _this.$root.$on("clearSelection", () => {
      _this.selectedRows = [];
    });
  },
  beforeDestroy() {
    this.$root.$off("clearSelection");
  },
  computed: {
    selectedAll: {
      set(param) {
        this.selectedRows = [];
        if (param && this.rowData.length) {
          for (let i = this.rowData.length - 1; i >= 0; i--) {
            this.selectedRows.push(this.rowData[i].id);
          }
        }
      },
      get() {
        let totalItems = this.rowData.length;
        return totalItems > 0 ? this.selectedRows.length === totalItems : false;
      },
    },
  },
};
</script>
